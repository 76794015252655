// Click a team member thumb
document.addEventListener('click', function(event)
{
	if (event.target.matches('.team_member_thumb'))
	{
		event.preventDefault();

		var $team_member = event.target;
		var url = $team_member.getAttribute('href');

		// TODO loading state?

		fetch(url, {
			method: 'POST',
			headers: {
				'X-Requested-With': 'XMLHttpRequest', // Tell Craft this is an AJAX request
		        'X-CSRF-Token': window.csrfTokenValue, // So Craft knows that the request is authentically from this site
			},
		})
		.then(function(response)
		{
			return response.text();
		})
		.then(function(html)
		{
			var $modal = document.querySelector('#team_member_modal_wrapper');
			$modal.querySelector('.content').innerHTML = html;
			$modal.classList.add('show');
		})
		.catch(function(err)
		{
			alert('There was an error – please try again');
	        // console.log('Failed to fetch page: ', err);
	    });

		return false;
	}
});
