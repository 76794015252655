// JS root file - include all JS files here
require('./_misc');
require('./_accordions');
require('./_carousel');
require('./_download');
require('./_portfolio');
require('./_team');

// Root CSS file
require('../scss/index.scss');
