// Prepare accordions
var $accordions = document.querySelectorAll('.accordion')
if ($accordions)
{
    for (var i = 0; i < $accordions.length; i++)
    {
        var $accordion = $accordions[i];
        if ($accordion.classList.contains('open'))
        {
            var $accordion_content = $accordion.querySelector('.accordion_content');
            var height = $accordion_content.querySelector('.inner').clientHeight;

            $accordion_content.style.height = height + 'px';
        }
    }
}

function toggle_accordion(element)
{
    var $accordion = element.closest('.accordion');

    var $accordion_content = $accordion.querySelector('.accordion_content');
    if ($accordion_content.clientHeight == 0)
    {
        // Close any other open accordions
        var $open_accordions = $accordion.closest('.accordions').querySelectorAll('.accordion.open');
        for (var i = 0; i < $open_accordions.length; i++)
        {
            var $open_accordion = $open_accordions[i];
            $open_accordion.querySelector('.accordion_content').style.height = '0px';
            $open_accordion.classList.remove('open');
        }

        // Open the selected accordion
        var height = $accordion_content.querySelector('.inner').clientHeight;
        $accordion_content.style.height = height + 'px';
        $accordion.classList.add('open');
    }
    else
    {
        // Close the selected accordion
        $accordion_content.style.height = '0px';
        $accordion.classList.remove('open');
    }
}

// Show/hide accordion content
document.addEventListener('click', function(event)
{
	if (event.target.matches('.accordion_title'))
	{
        toggle_accordion(event.target);
	}
});
