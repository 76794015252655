const fitvids = require('fitvids');
fitvids();

// Hide modal
document.addEventListener('click', function(event)
{
	if (event.target.matches('.close_modal_btn') || event.target.matches('.modal_wrapper'))
	{
		document.querySelector('.modal_wrapper').classList.remove('show');
	}
});

// Replace any links with their data-href value
// E.g. <a href="/page" data-href="/different-page"> will become <a href="/different-page">
// This is a way to send users to a different page while maintaining SEO
var $links_to_replace = document.querySelectorAll('a[data-href]');
for (var i = 0; i < $links_to_replace.length; i++)
{
	var $link = $links_to_replace[i];
	var new_link = $link.getAttribute('data-href');
	$link.setAttribute('href', new_link);
	$link.removeAttribute('data-href');
}

// Mobile menu
document.addEventListener('click', function(event)
{
	// Hide menu
	if (event.target.matches('body.menu_open #menu_btn'))
	{
		document.querySelector('body').classList.remove('menu_open');
	}
	// Show menu
	else if (event.target.matches('#menu_btn'))
	{
		document.querySelector('body').classList.add('menu_open');
	}
});
