var mouse_down_on_carousel = false;
var dragging_carousel = false;
var mouse_start_x;
var mouse_end_x;
var slide_width;
var current_slide_id;

var $carousel_being_dragged;

function change_slide(next_slide_id)
{
	var $carousel_wrapper = event.target.closest('.carousel_wrapper');
	var $current_slide = $carousel_wrapper.querySelector('.image.current');

	// Get the next slide
	var $next_slide = null;
	if (typeof next_slide_id === 'undefined')
	{
		$next_slide = $current_slide.nextElementSibling;
	}
	else
	{
		$next_slide = $carousel_wrapper.querySelector('.image.image_' + next_slide_id);
	}

	// If for some reason the slide we've chosen to come next doesn't exist (perhaps because we've reached the end), choose the first slide as the next one
	if (!$next_slide)
	{
		$next_slide = $carousel_wrapper.querySelector('.image');
	}

	// Get the next slide ID from the actual element, just in case
	next_slide_id = Number($next_slide.getAttribute('data-id')) + 1;

	// Move the slides along
	var slide_offset = $next_slide.offsetWidth * (next_slide_id - 1);
	$carousel_wrapper.querySelector('.carousel').style.transform = 'translateX(-' + slide_offset + 'px)';

	// Save state
	$current_slide.classList.remove('current');
	$next_slide.classList.add('current');
	$carousel_wrapper.querySelector('.dot.current').classList.remove('current');
	$carousel_wrapper.querySelector('.dot.dot_'+next_slide_id).classList.add('current');
}

// Clicking an image in a carousel advances to the next slide
document.addEventListener('click', function(event)
{
	if (dragging_carousel)
	{
		// Prevent browser from following link in slideshow
		event.preventDefault();
		event.stopImmediatePropagation();
	}
	else
	{
		if (event.target.matches('.carousel .image, .carousel .image img') && !dragging_carousel)
		{
			// Don't allow clicking on home page projects slideshow
			if (!event.target.matches('body.section_home .carousel .image, body.section_home .carousel .image img'))
			{
				change_slide();
			}
		}
	}
	
	dragging_carousel = false;
});

// Clicking a 'dot' in a carousel jumps to the chosen slide
document.addEventListener('click', function(event)
{
	if (event.target.matches('.carousel_navigation .dot'))
	{
		var next_slide_id = Number(event.target.getAttribute('data-id')) + 1;
		change_slide(next_slide_id);
	}
});

function carousel_drag_start(event)
{
	if (event.target.matches('.carousel .image'))
	{
		mouse_start_x = event.pageX;

		$carousel_being_dragged = event.target.closest('.carousel');

		current_slide_id = Number($carousel_being_dragged.closest('.carousel_wrapper').querySelector('.image.current').getAttribute('data-id'));
		slide_width = event.target.offsetWidth;

		mouse_down_on_carousel = true;
	}
}

function carousel_drag_move(event)
{
	if (mouse_down_on_carousel)
	{
		$carousel_being_dragged.closest('.carousel_wrapper').classList.add('drag');

		dragging_carousel = true;
		mouse_end_x = event.pageX - mouse_start_x;

		var amount_to_scroll = mouse_end_x - (current_slide_id * slide_width);

		$carousel_being_dragged.style.transform = 'translateX(' + amount_to_scroll + 'px)';
	}
}

function carousel_drag_end(event)
{
	mouse_down_on_carousel = false;

	if (dragging_carousel)
	{
		$carousel_being_dragged.closest('.carousel_wrapper').classList.remove('drag');

		var next_slide_id = mouse_end_x < 0 ? current_slide_id + 1 : current_slide_id - 1;
		next_slide_id += 1;
		change_slide(next_slide_id);
	}
}

// Add drag-scroll functionality to carousel for TOUCH devices
document.addEventListener('touchstart', function(event)
{
	carousel_drag_start(event);
});

document.addEventListener('touchmove', function(event)
{
	carousel_drag_move(event);
});

document.addEventListener('touchend', function(event)
{
	carousel_drag_end(event);
});

// Add drag-scroll functionality to carousel for MOUSE devices
document.addEventListener('mousedown', function(event)
{
	carousel_drag_start(event);
});

document.addEventListener('mousemove', function(event)
{
	carousel_drag_move(event);
});

document.addEventListener('mouseup', function(event)
{
	carousel_drag_end(event);
});
