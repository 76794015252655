// Show download modal and populate form with download data
document.addEventListener('click', function(event)
{
	if (event.target.matches('.download .button'))
	{
		var $button = event.target;
		var filename = $button.getAttribute('data-filename');
		var $download_modal = document.querySelector('#download_modal_wrapper');
		$download_modal.classList.add('show');
		$download_modal.querySelector('#download_id_field').value = $button.getAttribute('data-id');
		$download_modal.querySelector('#download_filename_field').value = filename;
		$download_modal.querySelector('#download_url_hash_field').value = $button.getAttribute('data-url-hash');

		//ga('send', 'event', 'button', 'click', 'Brochure', filename);
	}
});

// Redirect to file after download form is successfully submitted
const download_form = document.querySelector('#download_modal_wrapper form');

if (download_form)
{
	download_form.addEventListener('freeform-ready', function(event)
	{
		const freeform = event.target.freeform;

		freeform.addOnSuccessfulAjaxSubmit(function(event, form, response)
		{
			// Note: will be blocked as a popup window if browser settings say to block popup windows
			var win = window.open(response.returnUrl, '_blank');
			win.focus();

			// window.location.href = response.returnUrl;
		});
	});
}
