// Loads a project by AJAX
function load_project(url)
{
	if (url)
	{
		// TODO loading state?

		fetch(url, {
			method: 'POST',
			headers: {
				'X-Requested-With': 'XMLHttpRequest', // Tell Craft this is an AJAX request
		        'X-CSRF-Token': window.csrfTokenValue, // So Craft knows that the request is authentically from this site
			},
		})
		.then(function(response)
		{
			return response.text();
		})
		.then(function(html)
		{
			var $modal = document.querySelector('#project_modal_wrapper');
			$modal.querySelector('.content').innerHTML = html;
			$modal.classList.add('show');
			// console.log(html);
		})
		.catch(function(err)
		{
			alert('There was an error – please try again');
	        // console.log('Failed to fetch page: ', err);
	    });
	}
}
window.load_project = load_project; // Make available outside this context

// Check hash for a project URL load the project if there is one
if (window.location.hash)
{
	var hash = window.location.hash.slice(1); //hash to string (= "myanchor")

	if (hash.substr(0, 6) == 'filter')
	{
		var filter_id = hash.substr(7);
		if (filter_id)
		{
			var $filter_button = document.querySelector('#project_category_filters .project_category[data-category-id="' + filter_id + '"]');
			if ($filter_button)
			{
				filter_projects_by_category($filter_button);
			}
		}
	}
	else
	{
		load_project(hash);
	}
}

// Toggle portfolio view: grid or map
document.addEventListener('change', function(event)
{
	if (event.target.matches('#portfolio_view input'))
	{
		var value = event.target.value;
		if (value == 'grid')
		{
			document.querySelector('#map_view').classList.remove('show');
			document.querySelector('#grid_view').classList.add('show');

			document.querySelector('#project_category_filters').style.display = '';
		}
		else if (value == 'map')
		{
			document.querySelector('#grid_view').classList.remove('show');
			document.querySelector('#map_view').classList.add('show');

			document.querySelector('#project_category_filters').style.display = 'none';

			// Center the map to the geometric center of all markers
			map.setCenter(bounds.getCenter());
			map.fitBounds(bounds);

			// Remove one zoom level to ensure no marker is on the edge
			map.setZoom(map.getZoom() - 1);

			if (map.getZoom() > 11)
			{
				map.setZoom(11);
			}
		}
	}
});

// Click a team member thumb
document.addEventListener('click', function(event)
{
	if (event.target.matches('.portfolio .thumb'))
	{
		event.preventDefault();

		load_project(event.target.getAttribute('href'));

		return false;
	}
});

function filter_projects_by_category($filter)
{
	var project_category = $filter.getAttribute('data-category-id');

	if (!project_category)
	{
		reset_project_filters();
	}
	else
	{
		// Decide whether we're turning it off or on, and deselect all other filters
		if ($filter.classList.contains('selected'))
		{
			document.querySelector('.project_category.selected').classList.remove('selected');
		}
		else
		{
			document.querySelector('.project_category.selected').classList.remove('selected');
			$filter.classList.add('selected');
		}

		var selected_filter = $filter.getAttribute('data-category-id');
		if (selected_filter)
		{
			// Loop project thumbs, deciding whether to hide or show
			var $project_thumbs = document.querySelectorAll('#grid_view .thumb');
			for (var i = 0; i < $project_thumbs.length; i++)
			{
				var $thumb = $project_thumbs[i];
				var project_has_selected_category = false;

				var entry_category_ids = [];
				if ($thumb.getAttribute('data-category-ids') !== '')
				{
					var entry_category_ids = $thumb.getAttribute('data-category-ids').split(' ');
				}

				if (entry_category_ids.length > 0)
				{
					if (entry_category_ids.indexOf(selected_filter) !== -1)
					{
						project_has_selected_category = true;
					}
				}

				// If project has selected category, show the thumb; otherwise hide it
				if (project_has_selected_category)
				{
					$thumb.closest('.cell').style.display = '';
				}
				else
				{
					$thumb.closest('.cell').style.display = 'none';
				}
			}
		}
		else
		{
			reset_project_filters();
		}
	}
}

function reset_project_filters()
{
	// Deselect all selected filters
	var $selected_filters = document.querySelectorAll('#project_category_filters .project_category.selected');
	for (var i = 0; i < $selected_filters.length; i++)
	{
		$selected_filters[i].classList.remove('selected');
	}

	// Select the 'all' option
	document.querySelector('#project_category_all').classList.add('selected');

	// Show all thumbs
	$project_thumbs = document.querySelectorAll('#grid_view .thumb');
	for (var i = 0; i < $project_thumbs.length; i++)
	{
		$project_thumbs[i].closest('.cell').style.display = '';
	}
}

// Click a project category filter
document.addEventListener('click', function(event)
{
	if (event.target.matches('#project_category_filters .project_category'))
	{
		var $filter = event.target;
		filter_projects_by_category($filter);
	}
});
